<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{ $t('edit-spot') }}
                                        <h4 v-if="this.spot_id" class="d-inline">
                                            - {{this.spot_id}}
                                        </h4>
                                    </h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('category*')" label-for="Category">
                                                <b-form-select :options="categories" value-field="id" text-field="name" v-model="$v.form.category_id.$model" @change="getSelectedSpotCategory($event)" :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null" aria-describedby="input-2-live-feedback">
                                                    <b-form-select-option v-if="categories.length === 0" :disabled = true>{{ $t('no-category-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback v-if="!$v.form.category_id.required" id="input-2-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('user*')" label-for="name">
                                                <b-form-select v-model="$v.form.user_id.$model" :state="$v.form.user_id.$dirty ? !$v.form.user_id.$error : null" aria-describedby="input-1-live-feedback" :options="users" value-field="id" text-field="name">
                                                    <b-form-select-option v-if="users.length === 0" :disabled = true>{{ $t('no-user-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group id="spot_date" :label="$t('spot-date')" label-for="spot_date">
                                                <b-form-input id="spot_date" type="datetime-local" v-model="form.spot_date"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('latitude*')" label-for="latitude">
                                                <b-form-input type="text" id="latitude" autocomplete="latitude" :placeholder="$t('latitude')" v-model="$v.form.latitude.$model" :state="$v.form.latitude.$dirty ? !$v.form.latitude.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.latitude.required"
                                                             id="input-1-live-feedback">{{ $t("field-required") }}
                                                  </b-form-invalid-feedback>
                                                  <b-form-invalid-feedback v-if="!$v.form.latitude.between"
                                                             id="input-2-live-feedback">
                                                   {{ $t("Value must be between -90 and 90") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('longitude*')" label-for="longitude">
                                                <b-form-input type="text" id="longitude" autocomplete="longitude" :placeholder="$t('longitude')"  v-model="$v.form.longitude.$model" :state="$v.form.longitude.$dirty ? !$v.form.longitude.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.longitude.required"
                                                        id="input-1-live-feedback">{{ $t("field-required") }}
                                                    </b-form-invalid-feedback>
                                                    <b-form-invalid-feedback v-if="!$v.form.longitude.between"
                                                             id="input-2-live-feedback">
                                                        {{ $t("Value must be between -180 and 180") }}
                                                    </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('spot-type*')" label-for="name">
                                                <b-form-select v-model="$v.form.spot_type_id.$model" :state="$v.form.spot_type_id.$dirty ? !$v.form.spot_type_id.$error : null" aria-describedby="input-1-live-feedback" :options="spot_types" value-field="id" text-field="name">
                                                    <b-form-select-option v-if="spot_types.length === 0" :disabled = true>{{ $t('no-spot-type-found') }}</b-form-select-option>
                                                </b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('location*')" label-for="name">
                                                <b-form-select @change="selectFixLocation($event)" v-model="$v.form.location_id.$model" :state="$v.form.location_id.$dirty ? !$v.form.location_id.$error : null" aria-describedby="input-1-live-feedback" :options="locations" value-field="id" text-field="name">
                                                    <b-form-select-option v-if="locations.length === 0" :disabled = true>{{ $t('no-location-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('fix-location')" label-for="fix_location_id">
                                                <b-form-select @change="getFixLocation($event)"  v-model="form.fix_location_id" aria-describedby="input-1-live-feedback" :options="fixLocations"  value-field="id" text-field="name">
                                                    <b-form-select-option v-if="fixLocations.length === 0" :disabled = true>{{ $t('no-fix-location-found') }}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row" v-if="this.version_id == 1">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('details')" label-for="name">
                                                <b-form-textarea v-model="form.detail"></b-form-textarea>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="col-md-4">
                                                <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('approve-status') }}</label>
                                                <br>
                                                <toggle-button  @change="updateSpotStatus()" v-model="form.status" :sync="true" class="switchColor" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="col-md-4 pl-0">
                                                <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('spot-shareable') }}</label>
                                                <br>
                                                <toggle-button v-model="form.is_shareable" :sync="true" class="switchColor" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="this.version_id === 2 || this.version_id === 3">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('details')" label-for="name">
                                                <b-form-textarea v-model="form.detail"></b-form-textarea>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3 d-flex justify-content-end align-items-center">
                                            <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('approve-status') }}</label>
                                            <br>
                                            <toggle-button  @change="updateSpotStatus()" v-model="form.status" :sync="true" class="switchColor" />
                                        </div>
                                        <div class="col-md-3 d-flex justify-content-end align-items-center">
                                            <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('spot-shareable') }}</label>
                                            <br>
                                            <toggle-button v-model="form.is_shareable" :sync="true" class="switchColor" />
                                        </div>
                                    </div>
                                    <div class="row" v-if="this.version_id === 2 || this.version_id === 3">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('On Survey')" label-for="onSurvey">
                                                <b-form-select v-model="form.on_survey" @change="changeOnSurveyDropdown(form.on_survey)"  id="onSurvey" aria-describedby="onSurveyFeedback" :options="onSurveyOptions">
                                                </b-form-select>
                                                <b-form-invalid-feedback id="onSurveyFeedback">
                                                {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4" v-if="form.on_survey === 1">
                                            <b-form-group :label="$t('Survey Name*')" label-for="name">
                                                <b-form-select  v-model="$v.form.survey_id.$model" :state="$v.form.survey_id.$dirty ? !$v.form.survey_id.$error : null" aria-describedby="input-1-live-feedback" :options="surveys" value-field="id" text-field="name">
                                                    <b-form-select-option v-if="surveys.length === 0" :disabled = true>{{ $t('No Survey Found') }}</b-form-select-option>
                                                </b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.survey_id.required">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4" v-if="form.on_survey === 1">
                                            <b-form-group :label="$t('Survey Date')" label-for="name">
                                                <b-form-input type="text" id="survey_date" autocomplete="survey_date" :placeholder="$t('Survey Date')" v-model="form.survey_date" readonly></b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.survey_id.required">
                                                {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <hr>
                                    <b-form-group :label="$t('add-images-or-acoustic-data')" label-for="name">
                                    </b-form-group>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="upload-img">
                                                        <div class="del-icon" v-if="imageType0">
                                                            <a class="container1"><i class="fas fa-trash" @click="removeImage(0)"></i></a>
                                                        </div>
                                                        <expandable-image :src="form.url" v-if="imageType0 === 'image'"></expandable-image>
                                                        <!-- <EnlargeableImage :src="form.url" v-if="imageType0 === 'image'" :src_large="form.url"></EnlargeableImage> -->
                                                        <audio controls :src="form.url" v-if="imageType0 === 'audio'" @play="playAudio(0)" ref="audio0"></audio>
                                                    </div>
                                                    <b-form-group label label-for="input8">
                                                        <b-form-file ref="imageInput" class="mt-2" accept="image/png, image/jpeg, image/bmp" id="input8" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-image')" @change="onFileSelected($event, 'image')"></b-form-file>
                                                        <b-form-file ref="audioInput" class="mt-2" accept=".mp3,audio/*" id="inputaudio8" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-audio')" @change="onFileSelected($event, 'audio')"></b-form-file>
                                                        <div v-if="imageError" class="custom-invalid-feedback" id="input-2-live-feedback">
                                                            {{ $t("field-required") }}
                                                        </div>
                                                        <!-- <div v-if="imageSizeError" class="custom-invalid-feedback" id="input-9-live-feedback"> {{  $t('image-size-must-be-less-than-5mb') }}</div> -->
                                                        <div v-if="imageSizeError" class="custom-invalid-feedback" id="input-9-live-feedback">{{ $t('image-size-must-be-less-than-3mb') }}.</div>
                                                        <div v-if="audioSizeError && this.version_id === 1" class="custom-invalid-feedback"
                                                        id="input-9-live-feedback">
                                                        {{ $t('audio-size-must-be-less-than-3mb')}}
                                                        </div>
                                                        <div v-if="this.version_id !== 1 && audioSizeError" class="custom-invalid-feedback"
                                                        id="input-9-live-feedback">
                                                        {{ $t('audio-size-must-be-less-than-10mb') }}
                                                        </div>
                                                    </b-form-group>
                                                    <b-form-radio class="mt-1" v-model="form.primary_index" value="0" :checked="form.primary_index === 0" @change="selectPrimary(0)">
                                                      {{ $t("set-as-primary") }}
                                                    </b-form-radio>
                                                    <a href="#" @click="downloadItem(form.url, fileName1())">
                                                        {{ $t("download")}}
                                                        {{ form.media_types[0] }}
                                                    </a>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="upload-img">
                                                        <div class="del-icon" v-if="imageType1">
                                                            <a class="container1"><i class="fas fa-trash" @click="removeImage(1)"></i></a>
                                                        </div>
                                                        <expandable-image :src="form.url1" v-if="imageType1 === 'image'"></expandable-image>
                                                        <!-- <EnlargeableImage :src="form.url1" v-if="imageType1 === 'image'" :src_large="form.url1"></EnlargeableImage> -->
                                                        <audio controls :src="form.url1" v-if="imageType1 === 'audio'" @play="playAudio(1)" ref="audio1"></audio>
                                                    </div>
                                                    <b-form-group label label-for="input8">
                                                        <b-form-file ref="image1Input" class="mt-2" accept="image/png, image/jpeg, image/bmp" id="input" :placeholder="$t('upload-image')" @change="onFileSelected1($event, 'image')"></b-form-file>
                                                        <b-form-file ref="audio1Input" class="mt-2" accept=".mp3,audio/*" id="inputaudio9" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-audio')" @change="onFileSelected1($event, 'audio')"></b-form-file>
                                                    </b-form-group>
                                                    <!-- <div v-if="imageSizeError1" class="custom-invalid-feedback" id="input-10-live-feedback"> {{  $t('image-size-must-be-less-than-5mb') }}</div> -->
                                                    <div v-if="imageSizeError1" class="custom-invalid-feedback" id="input-10-live-feedback">{{ $t('image-size-must-be-less-than-3mb') }}.</div>
                                                    <div v-if="audioSizeError1 && this.version_id === 1" class="custom-invalid-feedback" id="input-10-live-feedback">{{ $t('audio-size-must-be-less-than-3mb')}}</div>
                                                    <div v-if="this.version_id !== 1 && audioSizeError1" class="custom-invalid-feedback"
                                                    id="input-9-live-feedback">
                                                    {{ $t('audio-size-must-be-less-than-10mb') }}
                                                    </div>
                                                    <b-form-radio class="mt-1" v-model="form.primary_index" value="1" @change="selectPrimary(1)" :checked="form.primary_index === 1">
                                                        {{ $t("set-as-primary") }}
                                                    </b-form-radio>
                                                    <a href="#" @click="downloadItem(form.url1, fileName2())">
                                                        {{ $t("download")}}
                                                        {{ form.media_types[1] }}
                                                    </a>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="upload-img">
                                                        <div class="del-icon" v-if="imageType2">
                                                            <a class="container1"><i class="fas fa-trash" @click="removeImage(2)"></i></a>
                                                        </div>
                                                        <expandable-image :src="form.url2" v-if="imageType2 === 'image'"></expandable-image>
                                                        <!-- <EnlargeableImage :src="form.url2" :src_large="form.url2" v-if="imageType2 === 'image'"></EnlargeableImage> -->
                                                        <audio controls :src="form.url2" v-if="imageType2 === 'audio'" @play="playAudio(2)" ref="audio2"></audio>
                                                    </div>
                                                    <b-form-group label label-for="input10">
                                                        <b-form-file ref="image2Input" class="mt-2" accept="image/png, image/jpeg, image/bmp" id="input10" :placeholder="$t('upload-image')" @change="onFileSelected2($event, 'image')"></b-form-file>
                                                        <b-form-file ref="audio2Input" class="mt-2" accept=".mp3,audio/*" id="inputaudio10" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-audio')" @change="onFileSelected2($event, 'audio')"></b-form-file>
                                                    </b-form-group>
                                                    <!-- <div v-if="imageSizeError2" class="custom-invalid-feedback" id="input-11-live-feedback"> {{  $t('image-size-must-be-less-than-5mb') }}</div> -->
                                                    <div v-if="imageSizeError2" class="custom-invalid-feedback" id="input-10-live-feedback">{{ $t('image-size-must-be-less-than-3mb') }}.</div>
                                                    <div v-if="audioSizeError2 && this.version_id === 1" class="custom-invalid-feedback" id="input-11-live-feedback">{{ $t('audio-size-must-be-less-than-3mb')}}</div>
                                                    <div v-if="this.version_id !== 1 && audioSizeError2" class="custom-invalid-feedback"
                                                        id="input-9-live-feedback">
                                                        {{ $t('audio-size-must-be-less-than-10mb') }}
                                                    </div>
                                                    <b-form-radio class="mt-1" v-model="form.primary_index" value="2" @change="selectPrimary(2)" :checked="form.primary_index === 2">
                                                     {{ $t("set-as-primary") }}
                                                    </b-form-radio>
                                                    <a href="#" @click="downloadItem(form.url2, fileName3())">
                                                        {{ $t("download")}}
                                                        {{ form.media_types[2] }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <vue-tabs active-tab-color="#9c27b0" active-text-color="white" type="pills">
                                        <v-tab :title="$t('data-collection-questions')" icon="" class="edit-tab-body">
                                            <div class="row mt-2">
                                                <div class="col-md-4" v-if="version_id == 2 && is_pro_sync == 1 && projectID !== null">
                                                    <b-form-group :label="$t('Project (Survey Name)')" label-for="project_name">
                                                        <b-form-select 
                                                        aria-disabled="true"
                                                        v-model="selectedProjectId" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="projectOptions" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        @change="onProjectChange"
                                                        disabled>
                                                        <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                            {{ $t('No Project Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-12" v-for="category in form.questions" :key="category.id">
                                                    <h5>{{category.category.name}}</h5>
                                                    <div v-for="question in category.questions" :key="question.question">
                                                        <div class="row mt-2">
                                                            <div class="col-md-6">{{question.question}}</div>
                                                            <div class="col-md-6" v-if="question.responses">
                                                                <b-form-select v-model="question.answer"
                                                                    :options="question.responses" class="custom-select "
                                                                    :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && questionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && questionError !== true }">
                                                                </b-form-select>
                                                                <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6" v-else>
                                                                <b-form-input type="text" placeholder v-model="question.answer"
                                                                    :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && questionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && questionError !== true }">
                                                                </b-form-input>
                                                                <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="question.answer === 'Yes' && question.subQuestions">
                                                            <div class="row mt-2" v-for="sub_question in question.subQuestions" :key="sub_question.id">
                                                                <div class="col-md-6">{{sub_question.question}}</div>
                                                                <div class="col-md-6" v-if="sub_question.answer_type == 'D'">
                                                                    <b-form-select v-model="sub_question.answer" :options="sub_question.responses" class="custom-select"></b-form-select>
                                                                </div>
                                                                <div class="col-md-6" v-if="sub_question.answer_type == 'I'">
                                                                    <b-form-input type="text" id="sub_question_response" placeholder v-model="sub_question.answer"></b-form-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-tab>
                                        <v-tab :title="$t('post-process-data')" class="edit-tab-body">
                                            <!-- <div class="row" v-if="form.ppd_questions.length > 0">
                                                <div class="col-md-12" v-for="question in form.ppd_questions" :key="question.id">
                                                    <div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-6">{{question.question}}</div>
                                                            <div class="col-md-6" v-if="question.responses.length !== 0">
                                                                <select class="custom-select" v-model="question.answer">
                                                                    <option
                                                                        v-for="response in question.responses"
                                                                        :key="response.name"
                                                                        :value="response.name"
                                                                    >{{response.name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        <div class="col-md-6" v-else>
                                                            <b-form-input type="text" placeholder v-model="question.answer"></b-form-input>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="question.answer === 'Yes' && question.sub_questions">
                                                        <div class="row mt-2" v-for="sub_question in question.sub_questions" :key="sub_question.id">
                                                            <div class="col-md-6">{{sub_question.question}}</div>
                                                            <div class="col-md-6" v-if="sub_question.answer_type === 'D'">
                                                                <b-form-select v-model="sub_question.answer" :options="sub_question.sub_questions_response" value-field="name" text-field="name" class="custom-select"></b-form-select>
                                                            </div>
                                                            <div class="col-md-6" v-if="sub_question.answer_type === 'I'">
                                                                <b-form-input type="text" id="sub_question_response" placeholder v-model="sub_question.answer"></b-form-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="row" v-if="form.ppd_questions.length > 0">
                                                <div class="col-md-12" v-for="question in form.ppd_questions" :key="question.id">
                                                    <div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-6">{{question.question}}</div>
                                                            <div class="col-md-6" v-if="question.responses.length !== 0">
                                                                <b-form-select v-model="question.answer"
                                                                    :options="question.responses" class="custom-select "
                                                                    value-field="name" text-field="name"
                                                                    :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && ppdQuestionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && ppdQuestionError !== true }">
                                                                </b-form-select>
                                                                <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && ppdQuestionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>
                                                        <div class="col-md-6" v-else>
                                                            <b-form-input type="text" placeholder v-model="question.answer"
                                                                :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && ppdQuestionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && ppdQuestionError !== true }">
                                                            </b-form-input>
                                                            <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                class="custom-invalid-feedback"
                                                                id="input-2-live-feedback">
                                                                {{ $t("field-required") }}
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="question.answer === 'Yes' && question.sub_questions">
                                                        <div class="row mt-2" v-for="sub_question in question.sub_questions" :key="sub_question.id">
                                                            <div class="col-md-6">{{sub_question.question}}</div>
                                                            <div class="col-md-6" v-if="sub_question.answer_type === 'D'">
                                                                <b-form-select v-model="sub_question.answer" :options="sub_question.sub_questions_response" value-field="name" text-field="name" class="custom-select"></b-form-select>
                                                            </div>
                                                            <div class="col-md-6" v-if="sub_question.answer_type === 'I'">
                                                                <b-form-input type="text" id="sub_question_response" placeholder v-model="sub_question.answer"></b-form-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-tab>
                                        <v-tab :title="$t('weather')" class="edit-tab-body"  v-if="this.version_id === 2 || this.version_id === 3">
                                            <div class="row mt-2">
                                                <div class="col-md-6 pl-0">
                                                    <h5 class="p-3">{{ $t('local-weather') }}</h5>
                                                </div>
                                                <div class="col-md-6 d-flex justify-content-end align-items-center">
                                                    <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('update-manually') }}</label>
                                                    <br>
                                                    <toggle-button v-model="form.update_local_manually" :sync="true" class="switchColor" />
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-12" v-for="(weather, key) in weatherData.localWeather" :key="key">
                                                    <div class="row mt-2">
                                                        <!-- {{ weatherData['localWeather'][key].value }} -->
                                                       <!-- <div class="col-md-6">{{ key | snakeToText }}</div>  -->
                                                        <div class="col-md-6">{{ $t(weather.name) }}</div>
                                                        <div class="col-md-6">
                                                            <b-form-input type="text" placeholder v-model="weatherData['localWeather'][key].value"></b-form-input>
                                                            <!-- <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                class="custom-invalid-feedback"
                                                                id="input-2-live-feedback">
                                                                {{ $t("field-required") }}
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="row mt-2">
                                                <div class="col-md-6 pl-0">
                                                    <h5 class="p-3">{{ $t('marine-weather') }}</h5>
                                                </div>
                                                <div class="col-md-6 d-flex justify-content-end align-items-center">
                                                    <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('update-manually') }}</label>
                                                    <br>
                                                    <toggle-button v-model="form.update_marine_manually" :sync="true" class="switchColor" />
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-12" v-for="(weather, key) in weatherData.marineWeather" :key="key">
                                                    <div class="row mt-2">
                                                        <!-- {{ weatherData['localWeather'][key].value }} -->
                                                       <!--  <div class="col-md-6">{{ key | snakeToText }}</div> -->
                                                       <div class="col-md-6">{{ $t(weather.name) }}</div>
                                                        <div class="col-md-6">
                                                            <b-form-input type="text" placeholder v-model="weatherData['marineWeather'][key].value"></b-form-input>
                                                            <!-- <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                class="custom-invalid-feedback"
                                                                id="input-2-live-feedback">
                                                                {{ $t("field-required") }}
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-tab>
                                        <v-tab v-if="version_id == 2 && is_pro_sync == 1" :title="$t('Environment')" class="edit-tab-body">
                                            <div class="row" v-if="form.desktop_weather_data.length > 0">
                                                <div class="col-md-12" v-for="question in form.desktop_weather_data" :key="question.id">
                                                    <div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-6">{{ $t(formatFieldName(question.field_name)) }}
                                                                <span class="unit-text">
                                                                    <!-- Dynamically show the unit based on the question field -->
                                                                    <template v-if="question.field_name === 'wind_speed' && question.wind_speed_unit">
                                                                        ({{ question.wind_speed_unit.split('_')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ') || '' }})
                                                                    </template>
                                                                    <template v-if="question.field_name === 'swell_height' && question.swell_height_unit">
                                                                        ({{ question.swell_height_unit.split('_')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ') || '' }})
                                                                    </template>
                                                                    <template v-if="question.field_name === 'visibility' && question.visibility_unit">
                                                                        ({{ question.visibility_unit.split('_')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ') || '' }})
                                                                    </template>
                                                                </span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <b-form-input type="text" placeholder v-model="question.field_value"
                                                                    :class="{ 'is-invalid' : (question.field_value === '' || question.field_value === null) && question.required === 1 && questionError === true, 'is-valid': question.field_value !== '' && question.field_value !== null && question.required === 1 && questionError !== true }">
                                                                </b-form-input>
                                                                <div v-if="(question.field_value === '' || question.field_value === null) && question.required === 1 && questionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mt-4" v-else>
                                                {{  $t('no-data-found-for-environment') }}
                                            </div>
                                        </v-tab>
                                        <v-tab v-if="version_id == 2 && is_pro_sync == 1" :title="$t('Effort')" class="edit-tab-body">
                                            <div class="row" v-if="form.desktop_effort_data.length > 0">
                                                <div class="col-md-12" v-for="question in form.desktop_effort_data" :key="question.id">
                                                <div>
                                                    <div class="row mt-2">
                                                    <div class="col-md-6">{{ $t(formatFieldName(question.field_name)) }}</div>
                                                    <div class="col-md-6" v-if="question.useSelect">
                                                        <b-form-select v-model="question.field_value"
                                                        :options="question.options" class="custom-select"
                                                        value-field="name" text-field="name"
                                                        :class="{ 'is-invalid' : (question.field_value === '' || question.field_value === null) && question.required === 1 && questionError === true, 'is-valid': question.field_value !== '' && question.field_value !== null && question.required === 1 && questionError !== true }">
                                                        </b-form-select>
                                                        <div v-if="(question.field_value === '' || question.field_value === null) && question.required === 1 && questionError === true"
                                                        class="custom-invalid-feedback"
                                                        id="input-2-live-feedback">
                                                        {{ $t("field-required") }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-else>
                                                        <b-form-input type="text" placeholder v-model="question.field_value"
                                                        :class="{ 'is-invalid' : (question.field_value === '' || question.field_value === null) && question.required === 1 && questionError === true, 'is-valid': question.field_value !== '' && question.field_value !== null && question.required === 1 && questionError !== true }">
                                                        </b-form-input>
                                                        <div v-if="(question.field_value === '' || question.field_value === null) && question.required === 1 && questionError === true"
                                                        class="custom-invalid-feedback"
                                                        id="input-2-live-feedback">
                                                        {{ $t("field-required") }}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="text-center mt-4" v-else>
                                                {{  $t('no-data-found-for-effort') }}
                                            </div>
                                            </v-tab>
                                    </vue-tabs>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            
                                            <b-button type="submit" variant="success"  :disabled="isDisabled || isLoading2" class="btn float-right btn-success">
                                                <template v-if="isLoading2">
                                                    <i class="fas fa-spinner fa-spin"></i>{{$t('updating')}}...
                                                </template>
                                                <template v-else>
                                                    <span v-if="this.form.id">{{ $t('update-btn') }}</span>
                                                </template>
                                            </b-button>
                                        </div>
                                    </div>
                                    <!--                                </form>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
    import Vue from "vue";
    import API from "@/api";
    import Snotify, {
        SnotifyPosition
    } from "vue-snotify";
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs,
        between,
    } from "vuelidate/lib/validators";
    import Tinybox from "vue-tinybox";
    import EnlargeableImage from "@diracleo/vue-enlargeable-image";
    import Compressor from 'compressorjs';
    import VueExpandableImage from 'vue-expandable-image'
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop
        }
    };
    Vue.use(VueExpandableImage);
    Vue.use(Snotify, options);
    Vue.component("Tinybox", Tinybox);
    Vue.component("EnlargeableImage", EnlargeableImage);
    // const MAX_SIZE = 104857600;
    const MAX_SIZE = 10485760;
    const MAX_SIZE_CS_VERSION = 3145728;
    export default {
        name: "Users",
        mixins: [validationMixin],
        data() {
            return {
                currentAudioIndex: -1,
                version_id:null,
                is_pro_sync: null,
                selectedProjectId: null,
                projectOptions: [],
                form: {
                    id: "",
                    spot_id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    category_id: "",
                    user_id: "",
                    spot_type_id: "",
                    image: "",
                    image1: "",
                    image2: "",
                    media_types: [],
                    url: "",
                    url1: "",
                    url2: "",
                    primary_image: "",
                    primary_index: '',
                    latitude: "",
                    longitude: "",
                    location_id: "",
                    fix_location_id: "",
                    spot_date: "",
                    detail: "",
                    questions: [],
                    questions2: [],
                    ppd_questions: [],
                    spot_ppd_questions: [],
                    desktop_weather_data: [],
                    desktop_effort_data: [],
                    status: true,
                    from_admin: true,
                    is_shareable:true,
                    update_local_manually: false,
                    update_marine_manually: false,
                    on_survey:0,
                    survey_id:"",
                    survey_date: null,
                    // selectedTab: 'Data Collection Questions',
                },
                weatherData: {
                    'localWeather': {
                        state: {
                            'name': 'state', 'unit': '', 'value': '',
                        },
                        temp_c: {
                            'name': 'temperature (°C)', 'unit': '', 'value': '',
                        },
                        feelslike_c: {
                            'name': 'feels-like (°C)', 'unit': '', 'value': '',
                        },
                        cloud: {
                            'name': 'Cloud Cover (%)', 'unit': '', 'value': '',
                        },
                        wind_degree: {
                            'name': 'wind-direction (°)', 'unit': '', 'value': '',
                        },
                        wind_mph: {
                            'name': 'wind-speed (mph)', 'unit': '', 'value': '',
                        },
                        vis_miles: {
                            'name': 'visibility (mi)', 'unit': '', 'value': '',
                        },
                        precip_mm: {
                            'name': 'precipitation (mm)', 'unit': '', 'value': '',
                        },
                        humidity: {
                            'name': 'humidity (%)', 'unit': '', 'value': '',
                        },
                        pressure_in: {
                            'name': 'pressure (in)', 'unit': '', 'value': '',
                        },
                    },
                    'marineWeather': {
                        state: {
                            'name': 'state', 'unit': '', 'value': ''
                        },
                        temp_c: {
                            'name': 'temperature (°C)', 'unit': '', 'value': ''
                        },
                        feelslike_c: {
                            'name': 'feels-like (°C)', 'unit': '', 'value': ''
                        },
                        cloud: {
                            'name': 'Cloud Cover (%)', 'unit': '', 'value': '',
                        },
                        wind_degree: {
                            'name': 'wind-direction (°)', 'unit': '', 'value': '',
                        },
                        wind_mph: {
                            'name': 'wind-speed (mph)', 'unit': '', 'value': '',
                        },
                        vis_miles: {
                            'name': 'visibility (mi)', 'unit': '', 'value': '',
                        },
                        precip_mm: {
                            'name': 'precipitation (mm)', 'unit': '', 'value': '',
                        },
                        sig_ht_mt: {
                            'name': 'significant-wave-height (m)', 'unit': '', 'value': ''
                        },
                        swell_ht_mt: {
                            'name': 'swell-wave-height (m)', 'unit': '', 'value': ''
                        },
                        swell_dir: {
                            'name': 'Swell Direction (°)', 'unit': '', 'value': ''
                        },
                        swell_period_secs: {
                            'name': 'swell-period (sec)', 'unit': '', 'value': ''
                        },
                        water_temp_c: {
                            'name': 'water-temperature (°C)', 'unit': '', 'value': ''
                        }
                    }
                },
                training_status: {
                    0: "No",
                    1: "Yes"
                },
                spot_type: "",
                imageError: "",
                imageSizeError: false,
                imageSizeError1: false,
                imageSizeError2: false,
                audioSizeError: false,
                audioSizeError1: false,
                audioSizeError2: false,
                isLoading2:false,
                images: [],
                index: "",
                isDisabled: false,
                readonly: true,
                showPassword: true,
                showPassword1: true,
                inputType: "password",
                inputType1: "password",
                locations: [],
                fixLocations: [],
                spot_types: [],
                users: [],
                categories: [],
                surveys:[],
                client_id: JSON.parse(localStorage.getItem('client_id')),
                questionError: false,
                ppdQuestionError: false,
                spot_id: '',
                imageType0: '',
                imageType1: '',
                imageType2: '',
                projectID: '',
            };
        },

        validations: {
            form: {
                category_id: {
                    required
                },
                user_id: {
                    required
                },
                latitude: {
                    required,
                        between(value) {
                            return between(-90, 90)(value)
                        }
                },
                longitude: {
                    required,
                        between(value) {
                            return between(-180, 180)(value)
                        }
                },
                location_id: {
                    required
                },
                spot_type_id: {
                    required
                },
                survey_id: {
                    required() {
                        if (this.form.on_survey === 1) {
                            return !!this.form.survey_id;
                        } else {
                            return true;
                        }
                    }
                }
            }
        },
        beforeCreate() {},
        beforeMount() {
            // this.getSpot();
            this.setPrimaryImage();
            this.updateSurveyDate();
        },
        mounted() {
            this.form.id = this.$route.params.id;
            this.spot_type = this.$route.params.type;
            this.getSpot();
            this.getClientUsers();
            this.getAllSurveys();
            this.getNames();
            // this.getClientLocations();
            // this.getUserSpotTypes();
        },
        created() {
            this.getAppSettings();
        },
        updated() {
            // this.form.questions.push();
        },
        computed: {
            onSurveyOptions() {
                return [
                    { value: 0, text: 'No' },
                    { value: 1, text: 'Yes' },
                ];
            },
        },
        methods: {
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                        this.projectOptions = response.data.map(project => ({
                            id: project.id,
                            name: project.project_name
                        }));

                        // Wait for projectOptions to be populated
                        this.$nextTick(() => {
                            const selectedProject = this.projectOptions.find(project => project.id === this.projectID);
                            if (selectedProject) {
                                this.selectedProjectId = selectedProject.id;
                                this.selectedProjectName = selectedProject.name;
                            }
                        });
                    },
                    err => {
                        console.error('Error fetching project names:', err);
                    }
                );
            },
            updateSurveyDate() {
                const selectedSurvey = this.surveys.find(survey => survey.id === this.form.survey_id);
                if (selectedSurvey) {
                    this.form.survey_date = selectedSurvey.survey_date;
                } else {
                    this.form.survey_date = null;
                }
            },
            changeOnSurveyDropdown() {
             console.log('questionSS :',this.form.on_survey);
                if(this.form.on_survey === 0){
                    this.form.survey_id = "";
                }
            },
            playAudio(index) {
                this.audioPlayer = this.$refs.audioPlayer;
                if (this.audioPlayer) {
                    this.audioPlayer.play();
                    this.audioPlayer.addEventListener('timeupdate', this.updateCurrentTime);
                    this.audioPlayer.addEventListener('durationchange', this.updateAudioDuration);
                }
            },
            pauseAudio(index) {
                if (this.audioPlayer) {
                    this.audioPlayer.pause();
                }
            },
            seekAudio() {
                if (this.audioPlayer) {
                    this.audioPlayer.currentTime = this.currentTime;
                }
            },
            updateCurrentTime() {
                this.currentTime = this.audioPlayer.currentTime;
            },
            updateAudioDuration() {
                this.audioDuration = this.audioPlayer.duration;
            },
            removeImage(index) {
                let imageType;
            switch(index) {
                case 0:
                    imageType = this.imageType0;
                    break;
                case 1:
                    imageType = this.imageType1;
                    break;
                case 2:
                    imageType = this.imageType2;
                    break;
                default:
                    imageType = '';
            }

            let fileTypeText;
            if (imageType === 'audio') {
                fileTypeText = 'audio';
            } else {
                fileTypeText = 'image';
            }

            this.$swal({
                title: 'Are you sure?',
                text: `You won't be able to get this ${fileTypeText} back.`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm!'
                }).then((result) => {
                    if (result.value) {
                        if (index === 0) {
                            this.form.url = '';
                            this.imageType0 = '';
                            this.imageError = true;
                            this.form.image = 'deleted';
                            this.form.media_types[0] = '';
                            this.$refs.imageInput.reset();
                            this.$refs.audioInput.reset();
                            this.getAppSettings();
                        } else if (index === 1) {
                            this.form.url1 = '';
                            this.imageType1 = '';
                            this.form.image1 = 'deleted';
                            this.form.media_types[1] = '';
                            this.$refs.image1Input.reset();
                            this.$refs.audio1Input.reset();
                        } else if (index === 2) {
                            this.form.url2 = '';
                            this.imageType2 = '';
                            this.form.image2 = 'deleted';
                            this.form.media_types[2] = '';
                            this.$refs.image2Input.reset();
                            this.$refs.audio2Input.reset();
                        }
                        // this.$snotify.success("Image removed successfully");
                    }
                });
            },
            getFixLocation(e) {
                const data = {
                    id: e,
                };
                API.getFixLocation(
                    data,
                    data => {
                        this.form.longitude = data.longitude;
                        this.form.latitude = data.latitude;
                    },
                    err => {}
                );
            },
            selectFixLocation(e) {
                const data = {
                    location_id: e,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.fixLocationsDropdown(
                    data,
                    data => {
                        if(data.status === 200) {
                            this.fixLocations = data.data;
                        }
                    },
                    err => {}
                )
            },
            onSubmit() {
                this.$v.form.$touch();
                var questionErr = false;
                var ppdQuestionError = false;
                this.form.questions.forEach(function(category, index) {
                    category.questions.forEach(function(question, i) {
                        if((question.answer === '' || question.answer === null) && question.required === 1){
                            questionErr = true;
                        }
                    })
                });

                this.form.ppd_questions.forEach(function(question, i) {
                    if((question.answer === '' || question.answer === null) && question.required === 1){
                        ppdQuestionError = true;
                    }
                });
                if(ppdQuestionError) {
                    this.ppdQuestionError = true;
                    // this.selectedTab = 'Post Process Data';
                    this.$snotify.error('Post Process Data answer fields missing');
                    return;
            }
                if(questionErr || ppdQuestionError){
                    this.questionError = true;
                    this.ppdQuestionError = true;
                    return;
                }

                if (this.$v.form.$anyError) {
                    return;
                }
                if(this.imageError == "true" || this.imageError == true || this.imageError == 'true' ) {
                    return;
                }

                if (this.$v.form.$anyError || questionErr || ppdQuestionError || (this.imageError === "true" && this.form.url !== '')) {
                    return; // Don't submit the form if there are validation errors or missing required data
                }
                const fd = new FormData();
                if (this.form.image !== undefined && this.form.image instanceof File) {
                    fd.append('image', this.form.image, this.form.image.name)
                } else {
                    fd.append('image', this.form.image)
                }
    
                if (this.form.image1 !== undefined && this.form.image1 instanceof File) {
                    fd.append('image1', this.form.image1, this.form.image1.name)
                } else {
                    fd.append('image1', this.form.image1)
                }
    
                if (this.form.image2 !== undefined && this.form.image2 instanceof File) {
                    fd.append('image2', this.form.image2, this.form.image2.name)
                } else {
                    fd.append('image2', this.form.image2)
                }
                fd.append('id', this.form.id);
                fd.append('client_id', this.form.client_id);
                fd.append('category_id', this.form.category_id);
                fd.append('user_id', this.form.user_id);
                fd.append('spot_type_id', this.form.spot_type_id);
                fd.append('location_id', this.form.location_id);
                fd.append('fix_location_id', this.form.fix_location_id);
                fd.append('survey_id', this.form.survey_id);
                fd.append('on_survey', this.form.on_survey);
                fd.append('detail', this.form.detail === null ? '' : this.form.detail);
                fd.append('spot_date', this.form.spot_date);
                fd.append('primary_index', this.form.primary_index);
                fd.append('primary_image', this.form.primary_image);
                fd.append('media_types', JSON.stringify(this.form.media_types));
                fd.append('status', this.form.status);
                fd.append('is_shareable', this.form.is_shareable);
                fd.append('from_admin', this.form.from_admin);
                fd.append('weatherData', JSON.stringify(this.weatherData));
                console.log('this.weatherData :', this.weatherData);
    
                if (this.form.latitude === '' || this.form.latitude === null) {
                    fd.append('latitude', this.latitude);
                } else {
                    fd.append('latitude', this.form.latitude);
                }
    
                if (this.form.longitude === '' || this.form.longitude === null) {
                    fd.append('longitude', this.longitude);
                } else {

                    fd.append('longitude', this.form.longitude);
                }
    
                fd.append('questions', JSON.stringify(this.form.questions));
                fd.append('ppd_questions', JSON.stringify(this.form.ppd_questions));
                fd.append('spot_ppd_questions', JSON.stringify(this.form.spot_ppd_questions));
                fd.append('client_id', this.form.client_id);
                fd.append('client_url', this.form.client_url);
                fd.append('update_local_manually', this.form.update_local_manually);
                fd.append('update_marine_manually', this.form.update_marine_manually);
                this.isDisabled = true;
                this.isLoading2 = true;
                
                API.updateSpot(
                    fd,
                    data => {
                        this.isDisabled = false;
                        this.isLoading2 = false;
                        if (data.status === 200) {
                            this.$snotify
                                .success(data.message, {
                                    timeout: 1000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true
                                })
                                .on("destroyed", () => this.$router.push({
                                    name: "spots"
                                }));
                        } else {
                            this.$snotify.error(data.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                            this.form.fix_location_id = ''
                        }
                    },
                    err => {
                        this.isDisabled = false;
                        this.isLoading2 = false;
                        this.$snotify.error(err, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    }
                );
            },
        updateSpotStatus() {
            const data = {
                id: this.form.id,
                client_id: JSON.parse(localStorage.getItem('client_id')),
                status: this.form.status == true ? 'A':'I' 
            };
            API.updateSpotStatus(
                data,
                data => {
                    if (data.status === 200) {
                        this.$snotify.success(data.message);
                    }
                },
                err => {}
            );
        },
            async getSpot() {
                let data = {
                    id: this.form.id,
                    type: this.spot_type,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    project_id: this.selectedProjectId
                };
                await API.getSpot(
                    data,
                    data => {
                        this.form.category_id = data.data.category ? data.data.category[0].id : "";
                        this.getEditSpotCategories();
                        this.locations = data.locations;
                        this.projectID = data.data.project_id;
                        this.spot_types = data.spot_types;
                        this.surveys = data.surveys;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.client_id = data.data.client_id;
                        this.spot_id = data.data.spot_id;
                        this.form.questions = data.new_array;
                        this.form.ppd_questions = data.data.ppd_questions;
                        this.form.spot_ppd_questions = data.data.spot_ppd_questions;
                        this.form.desktop_weather_data = data.data.desktop_weather_data;
                        this.form.desktop_effort_data = data.data.desktop_effort_data;
                        this.form.categories = data.data.category;
                        this.form.user_id = data.data.user_id;
                        this.form.spot_type_id = data.data.spot_type_id;
                        this.form.survey_id = data.data.survey_id;
                        this.form.on_survey = data.data.on_survey;
                        this.form.primary_image = data.data.primary_image;
                        if (data.data.image === null) {
                            this.form.url = '';
                            this.getAppSettings();
                        } else {
                            this.form.url = data.data.server_url + data.data.image;
                            this.imageError = '';
                        }
                        this.form.url1 = data.data.server_url + data.data.image1;
                        this.form.url2 = data.data.server_url + data.data.image2;
                        this.index = 1;
                        this.form.media_types = data.data.media_types ?? [];
                        this.imageType0 = this.form.media_types[0] ?? null;
                        this.imageType1 = this.form.media_types[1] ?? null;
                        this.imageType2 = this.form.media_types[2] ?? null;
                        this.form.image = data.data.image;
                        this.form.image1 = data.data.image1;
                        this.form.image2 = data.data.image2;
                        this.form.latitude = data.data.latitude;
                        this.form.longitude = data.data.longitude;
                        this.form.location_id = data.data.location_id;
                        this.form.fix_location_id = data.data.fix_location_id;
                        this.form.spot_date = data.data.spot_date;
                        this.form.detail = data.data.detail;
                        this.server_url = data.data.server_url;
                        this.form.status = data.data.status === "A" ? true : false;
                        this.form.is_shareable = data.data.is_shareable === 1 ? true : false;
                        this.form.update_local_manually = data.data.spot_local_weather.update_manually === 1 ? true : false;
                        this.form.update_marine_manually = data.data.spot_marine_weather.update_manually === 1 ? true : false;
                        // this.weatherData = data.data.weatherData;
                        this.selectFixLocation(this.form.location_id);
                        this.setPrimaryImage();
                        this.mapWeather(data.data.spot_local_weather, data.data.spot_marine_weather);
                        this.updateSurveyDate();
                        this.getNames();
                    },
                    err => {}
                );
            },
            setPrimaryImage() {
                if(this.form.image  === this.form.primary_image) {
                    this.form.primary_index = 0; 
                } else if(this.form.image1  === this.form.primary_image) {
                    this.form.primary_index = 1; 
                } else if(this.form.image2  === this.form.primary_image) {
                    this.form.primary_index = 2; 
                }
            },
            getClientUsers() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getClientUsers(data,
                    data => {
                        this.users = data.data;
                    },
                    err => {}
                );
            },
            getAllSurveys() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAllSurveys(data,
                    data => {
                        this.surveys = data.data;
                    },
                    err => {}
                );
            },
            getClientLocations() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getClientLocations(
                    data,
                    data => {
                        this.locations = data.data;
                    },
                    err => {}
                );
            },
            getUserSpotTypes() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getUserSpotTypes(
                    data,
                    data => {
                        this.spot_types = data.data;
                    },
                    err => {}
                );
            },
            downloadItem(url, fileName) { 
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                xhr.responseType = "blob";
                xhr.onload = function() {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    var tag = document.createElement('a');
                    tag.href = imageUrl;
                    tag.download = fileName;
                    document.body.appendChild(tag);
                    tag.click();
                    document.body.removeChild(tag);
                }
                xhr.send();
            },
            fileName1(){
                return this.spot_id + '_1';
            },
            fileName2(){
                return this.spot_id + '_2';
            },
            fileName3(){
                return this.spot_id + '_3';
            },
            getEditSpotCategories() {
                const data = {
                    client_id: this.client_id,
                    type: this.spot_type,
                    category_id: this.form.category_id
                };
                API.getEditSpotCategories(
                    data,
                    data => {
                        this.categories = data.data;
                    },
                    err => {}
                );
            },
            getSelectedSpotCategory() {
                const data = {
                    id: this.form.id,
                    type: this.spot_type,
                    client_id: this.client_id,
                    category_id: this.form.category_id
                };
                API.getSelectedSpotCategory(
                    data,
                    data => {
                        this.form.questions = data.new_array;
                    },
                    err => {}
                );
            },
            //delete
            checkType(index, type) {
                if (index === 0) {
                    if (this.form.media_types[index] === type) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            compressImage(file) {
            const maxWidth = 1000;
            const maxHeight = 1000;
            const quality = 0.8;
            const mimeType = file.type;
            return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth,
                maxHeight,
                quality,
                mimeType,
                success(result) {
                resolve(result);
                },
                error(error) {
                reject(error);
                },
            });
            });
        },
        mapWeather(spotLocalWeather, spotMarineWeather) {
            // Iterate through the keys in spotLocalWeather
            for (const key in spotLocalWeather) {
                if (this.weatherData.localWeather[key]) {
                    this.weatherData.localWeather[key].value = spotLocalWeather[key];
                }
            }
            // Iterate through the keys in spotMarineWeather
            for (const key in spotMarineWeather) {
                if (this.weatherData.marineWeather[key]) {
                    this.weatherData.marineWeather[key].value = spotMarineWeather[key];
                }
            }
        },
        async onFileSelected(event, type) {
            this.version_id;
            this.imageType0 = type;
            this.form.media_types[0] = type;
            if(this.form.media_types[0] === 'audio'){
                this.$refs.imageInput.reset();
            }else{
                this.$refs.audioInput.reset();

            }
            if(this.form.media_types[0] === 'image'){
                if(this.version_id === 2 || this.version_id === 3){
                        const compressedImage = await this.compressImage(event.target.files[0]);
                        this.form.image = compressedImage;
                        this.form.url = URL.createObjectURL(compressedImage);
                        this.imageSizeError = false;
                        this.audioSizeError = false;
                        this.imageError = false;  
                }
                else{
                    if(event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image = event.target.files[0];
                        this.form.url = URL.createObjectURL(this.form.image);
                        this.imageSizeError = false;
                        this.audioSizeError = false;
                        this.imageError = false;
                    }
                    else{
                        this.imageSizeError = true;
                    }
                }
            }
            else {
                 if(this.form.media_types[0] === 'audio'){
                    if(this.version_id === 2 || this.version_id === 3){
                        if (event.target.files[0].size <= MAX_SIZE) {
                            this.form.image = event.target.files[0];
                            this.form.url = URL.createObjectURL(this.form.image);
                            this.imageSizeError = false;
                            this.audioSizeError = false;
                            this.imageError = false;
                        }
                        else{
                            this.audioSizeError = true;
                        }
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                            this.form.image = event.target.files[0];
                            this.form.url = URL.createObjectURL(this.form.image);
                            this.imageSizeError = false;
                            this.audioSizeError = false;
                            this.imageError = false;
                        } 
                        // this.imageError = false;
                        else{
                        this.audioSizeError = true;
                        // this.imageError = false;
                        }
                    }
                       
                    }
                    // else{
                    //     this.imageSizeError = true;
                    //     // this.imageError = false;
                    // }
                       // this.imageSizeError = true;
                }
        },
        async onFileSelected1(event, type) {
            this.version_id;
            this.imageType1 = type;
            this.form.media_types[1] = type;
            if(this.form.media_types[1] === 'audio'){
                 this.$refs.image1Input.reset();
            }else{
                 this.$refs.audio1Input.reset();
            }
            if(this.form.media_types[1] === 'image'){
                if(this.version_id === 2 || this.version_id === 3){
                    const compressedImage = await this.compressImage(event.target.files[0]);
                    this.form.image1 = compressedImage;
                    this.form.url1 = URL.createObjectURL(compressedImage);
                    this.imageSizeError1 = false;
                    this.audioSizeError1 = false;
                }
                else{
                    if(event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image1 = event.target.files[0];
                        this.form.url1 = URL.createObjectURL(this.form.image1);
                        this.imageSizeError1 = false;
                        this.audioSizeError1 = false;
                        this.imageError1 = false;
                    }
                    else{
                        this.imageSizeError1 = true;
                    }
                } 
            }
            else {
                 if(this.form.media_types[1] === 'audio'){
                    if(this.version_id === 2 || this.version_id === 3){
                        if (event.target.files[0].size <= MAX_SIZE) {
                            this.form.image1 = event.target.files[0];
                            this.form.url1 = URL.createObjectURL(this.form.image1);
                            this.imageSizeError1 = false;
                            this.audioSizeError1 = false;
                            this.imageError1 = false;
                        }
                        else{
                            this.audioSizeError1 = true;
                        }
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image1 = event.target.files[0];
                        this.form.url1 = URL.createObjectURL(this.form.image1);
                        this.imageSizeError1 = false;
                        this.audioSizeError1 = false;
                        this.imageError1 = false;
                        } 
                        else{
                            this.audioSizeError1 = true;
                        }
                    }
                }
                    // else{
                    //     this.imageSizeError1 = true;
                    // }
                // this.imageSizeError1 = true;
            }
        },
        async onFileSelected2(event, type) {
            this.version_id;
            this.imageType2 = type;
            this.form.media_types[2] = type;
            if(this.form.media_types[2] === 'audio'){
                this.$refs.image2Input.reset();
            }else{
                this.$refs.audio2Input.reset();
            }
            if(this.form.media_types[2] === 'image'){
                if(this.version_id === 2 || this.version_id === 3){
                    const compressedImage = await this.compressImage(event.target.files[0]);
                    this.form.image2 = compressedImage;
                    this.form.url2 = URL.createObjectURL(compressedImage);
                    this.imageSizeError2 = false;
                    this.audioSizeError2 = false;
                }
                else {
                    if(event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image2 = event.target.files[0];
                        this.form.url2 = URL.createObjectURL(this.form.image2);
                        this.imageSizeError2 = false;
                        this.audioSizeError2 = false;
                        this.imageError2 = false;
                    }
                    else{
                        this.imageSizeError2 = true;
                    }
                }
            }
            else {
                if(this.form.media_types[2] === 'audio'){
                    if(this.version_id === 2 || this.version_id === 3){
                        if (event.target.files[0].size <= MAX_SIZE) {
                            this.form.image2 = event.target.files[0];
                            this.form.url2 = URL.createObjectURL(this.form.image2);
                            this.imageSizeError2 = false;
                            this.audioSizeError2 = false;
                            this.imageError2 = false;
                        }
                        else{
                            this.audioSizeError2 = true;
                        }
                    }
                    else {
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                            this.form.image2 = event.target.files[0];
                            this.form.url2 = URL.createObjectURL(this.form.image2);
                            this.imageSizeError2 = false;
                            this.audioSizeError2 = false;
                            this.imageError2 = false;
                        } 
                        else{
                            this.audioSizeError2 = true;
                        }
                    }   
                }
                    // else{
                    //     this.imageSizeError2 = true;
                    // }
                // this.imageSizeError2 = true;
            }
        },
        selectPrimary(index) {
            this.form.primary_index = index;
        },
        filters: {
            snakeToText(value) {
            return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
        },
        getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        // this.imageError = false
                        this.imageError = (data.data.require_photo === 1) ? 'true' : '';              
                    },
                    err => {
                    }
                )
        },
        formatFieldName(fieldName) 
        {
            return fieldName
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
    },
    watch: {
        'form.survey_id': function(newVal, oldVal) {
            this.updateSurveyDate();
        },
        'form.location_id': function(newLocationId, oldLocationId) {
            if (newLocationId !== oldLocationId) {
                this.selectFixLocation(newLocationId);
            }
        },
        projectID(newVal) {
            const selectedProject = this.projectOptions.find(project => project.id === newVal);
            if (selectedProject) {
                this.selectedProjectId = selectedProject.id;
                this.selectedProjectName = selectedProject.name;
            }
        }
    }
};
</script>

<style scoped>
    /* your passed-in element */
    
    .enlargeable-image>.slot {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        cursor: zoom-in;
    }
    
    
    /* default img if no element passed in */
    
    .enlargeable-image>.slot>img.default {
        max-width: 100%;
        vertical-align: middle;
    }
    
    
    /* passed-in element when growth is happening */
    
    .enlargeable-image.active>.slot {
        opacity: 0.3;
        filter: grayscale(100%);
    }
    
    
    /* full version that grows (background image allows seamless transition from thumbnail to full) */
    
    .enlargeable-image .full {
        cursor: zoom-out;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: none;
    }
    
    .enlargeable-image .full>img {
        object-fit: contain;
        width: 0;
        height: 0;
        display: none !important;
    }
    
    
    /* full version while getting bigger */
    
    .enlargeable-image .full.enlarging {
        display: flex;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        cursor: zoom-out;
        z-index: 3;
    }
    
    
    /* full version while at its peak size */
    
    .enlargeable-image .full.enlarged {
        display: flex;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        cursor: zoom-out;
        z-index: 2;
    }
    
    
    /* full version while getting smaller */
    
    .enlargeable-image .full.delarging {
        display: flex;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        cursor: zoom-in;
        z-index: 1;
    }
    
    .enlargeable-image .full.enlarged img {
        display: none !important;
        background-color: red;
        width: 100%;
        height: 100%;
    }
    .row .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
    .edit-tab-body {
        margin: 0px;
    }
    .avatar-img {
    position: relative;
    /* width: 100px;
    height: 100px;
    margin: auto; */
}
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
